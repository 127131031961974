import React from "react";
import banner from "../../Json/banner.json";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  link: {
    "&:hover": {
      textDecoration: "none",
    }
  },
  menuButtonLogin: {
    marginRight: 6,
    backgroundColor: "#E50B4c",
    color: "#fff",
    border: "1px solid #E50B4C",
    marginBottom: 15,
    "&:hover": {
      backgroundColor: "#fff",
      color: "#E50B4C",

      border: "1px solid #E50B4C",
    },
  },
}));

function Banner() {

  const classes = useStyles();


  const settings = {
    dots: false,
    infinite: true,
    fade: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 7000,
  };

  return (
    <div className="banner-warpper">
      <Slider {...settings}>
        <div>
          <img src={process.env.PUBLIC_URL + "/images/B1.jpg"} alt="logo" />
          <div className="banner-title">
            <h1> {banner["banner"].title}</h1>
            <span> {banner["banner"].description}</span>
            <form className="form desktop">
              <Link href="#onlineCourse" className={classes.link}>
                <Button variant="contained" color="inherit" className={classes.menuButtonLogin} >
                  ZU UNSEREN ANGEBOTEN
                </Button>
              </Link>
            </form>
          </div>
        </div>
        <div>
          <img src={process.env.PUBLIC_URL + "/images/B2.jpg"} alt="cover" />
          <div className="banner-title">
            <h1> {banner["banner"].title}</h1>
            <span> {banner["banner"].description}</span>
            <form className="form desktop">
              <Link href="#onlineCourse" className={classes.link}>
                <Button variant="contained" color="inherit" className={classes.menuButtonLogin} >
                  ZU UNSEREN ANGEBOTEN
                </Button>
              </Link>
            </form>
          </div>
        </div>
        <div>
          <img src={process.env.PUBLIC_URL + "/images/B3.jpg"} alt="cover" />
          <div className="banner-title">
            <h1> {banner["banner"].title}</h1>
            <span> {banner["banner"].description}</span>
            <form className="form desktop">
              <Link href="#onlineCourse" className={classes.link}>
                <Button variant="contained" color="inherit" className={classes.menuButtonLogin} >
                  ZU UNSEREN ANGEBOTEN
                </Button>
              </Link>
            </form>
          </div>
        </div>
      </Slider>
      <form className="form mobile">
        <Link href="#onlineCourse" className={classes.link}>
          <Button variant="contained" color="inherit" className={classes.menuButtonLogin} >
            ZU UNSEREN ANGEBOTEN
          </Button>
        </Link>
      </form>

    </div>
  );
}

export default Banner;
