import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
// import Link from "@material-ui/core/Link";
// import { Button } from "@material-ui/core";
// import TextField from "@material-ui/core/TextField";
// import TwitterIcon from "@material-ui/icons/Twitter";
// import YouTubeIcon from "@material-ui/icons/YouTube";
// import LinkedInIcon from "@material-ui/icons/LinkedIn";
import Address from "../../data/address.json";
import ReactMarkdown from "react-markdown";
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "#eeee",
    marginTop: 44,
  },
  icons: {
    display: "flex",
    paddingLeft: "20px",

    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "start",
      padding: "0px",
    },
    "& svg": {
      paddingRight: "10px",
      fontSize: "2rem",
    },
  },
  TwitterIcon: {
    color: "#1DA1F2",
  },
  YouTubeIcon: {
    color: "#FF0000",
  },
  LinkedInIcon: {
    color: "#0e76a8",
  },
  input: {
    width: "calc(90% - 120px)",
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      marginBottom: 15,
    },
    "& input": {
      backgroundColor: "#fff",
      borderRadius: 0,
    },
  },
  container: {
    padding: "22px 10px",
    [theme.breakpoints.down("xs")]: {
      padding: "15px",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    backgroundColor: "#E50B4C",
    color: "#fff",
    border: "1px solid #E50B4C",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#E50B4C",
      border: "1px solid #E50B4C",
    },
  },
}));
export const Newsletter = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container disableGutters>
        {" "}
        <Grid container className={classes.container}>
          {/* <Grid item md={6}>
          <h2>Newsletter</h2>
          <TextField
            className={classes.input}
            placeholder="Ihre E-Mail-Adresse"
            variant="outlined"
            type="email"
            InputLabelProps={{ shrink: false }}
          />
          <Button className={classes.menuButton} variant="contained">
            Anmelden
          </Button>
        </Grid> */}
          <Grid item md={6} sm={12} xs={12}>
            <h2>{Address[0].title}</h2>
            <div className="address">
              <div className="adr">
                <ReactMarkdown
                  source={Address[0].content}
                  escapeHtml={false}
                ></ReactMarkdown>
              </div>
              </div>
              </Grid>
          </Grid>
       
      </Container>
    </div>
  );
};
