import React from "react";
import Markdown from "react-markdown";
import ImprintJSon from "../data/imprint.json";
import { Container } from "@material-ui/core";

const Impressum = () => {
  return (
    <Container>
      <div className="giver-section inline">
        <h2>{ImprintJSon[0].title}</h2>
        <Markdown source={ImprintJSon[0].content} escapeHtml={false} />
      </div>
    </Container>
  );
};

export default Impressum;
