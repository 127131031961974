import React from "react";
import Markdown from "react-markdown";
import TermsJSon from "../data/terms.json";
import { Container } from "@material-ui/core";

const AGBs = () => {
  return (
    <Container>
      <div className="giver-section inline">
        <h2>{TermsJSon[0].title}</h2>
        <Markdown source={TermsJSon[0].content} escapeHtml={false} />
      </div>
    </Container>
  );
};

export default AGBs;
