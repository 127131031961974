import React from "react";
import { Container } from "@material-ui/core";
import Layout from "../layout";

const Thanks = () => {
  return (
    <Container maxWidth="lg">
      <Layout>
        <div className="page-wrapper">
          <h2>VIELEN DANK FÜR DEIN INTERESSE!</h2>
          <p>
            Wir freuen uns über Dein Interesse an unserem Bildungsangebot! Wir
            setzen uns in Kürze mit Dir zur weiteren Absprache in Verbindung.
            Herzliche Grüße,
          </p>
          <p> Dein kwiso-Team.</p>
        </div>
      </Layout>
    </Container>
  );
};

export default Thanks;
