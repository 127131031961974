import React from "react";
import {
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import axios from "axios";
import { useForm } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    backgroundColor: "#e50b4c",
    border: "1px solid #e50b4c",
    color: "#fff",
    margin: theme.spacing(3, 0, 2),
    minWidth: 350,

    [theme.breakpoints.down("xs")]: {
      minWidth: 200,
    },

    "&:hover": {
      color: "#e50b4c",
      backgroundColor: "#fff",
      border: "1px solid #e50b4c",
    },
  },
  notchedOutline: {
    border: "1px solid #000 !important",
  },
}));

export default function FormData() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [option, setOption] = React.useState();
  const classes = useStyles();

  const handleChangeSelect = (event) => {
    setOption(event.target.value);
  };

  const resetForm = () => {
    document.getElementById("form").reset();
  };

  const onSubmit = (data) => {
    const source = { source: "INDIVIDUAL" };
    const dataWithSource = { ...source, ...data };
    axios({
      method: "post",
      cors: true,
      url: "/send_contact_email",
      headers: { "content-type": "application/json" },
      data: dataWithSource,
    })
      .then((result) => {
        if (result.data.status === "success") {
          resetForm();
          window.location.href = "/thanksforsubmitting";
          return;
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <React.Fragment>
      <div className="form-layout">
        <h2 id="formular">Jetzt anmelden </h2>
        <form
          id="form"
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <InputLabel id="label">
                Kurstitel <span>*</span>
              </InputLabel>
              <TextField
                type="text"
                name="kurstitel"
                variant="outlined"
                label=""
                id="kurstitel"
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                {...register("kurstitel", { required: true })}
              />
              <div>
                {errors.kurstitel && (
                  <span className="error">Dies ist ein Pflichtfeld.</span>
                )}
              </div>
            </Grid>

            <Grid item xs={12} md={4}>
              <div>
                <InputLabel id="label">
                  Anrede <span>*</span>
                </InputLabel>
                <Select
                  variant="outlined"
                  fullWidth
                  labelId="label"
                  name="anrede"
                  id="select"
                  value={option}
                  {...register("anrede", { required: true })}
                  onChange={handleChangeSelect}
                  className={classes.notchedOutline}
                  inputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                >
                  <MenuItem value="herr">Herr</MenuItem>
                  <MenuItem value="frau">Frau</MenuItem>
                  <MenuItem value="ohne">ohne Nennung</MenuItem>
                </Select>
                <div>
                  {errors.anrede && (
                    <span className="error">Dies ist ein Pflichtfeld.</span>
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel id="label">
                Vorname <span>*</span>
              </InputLabel>
              <TextField
                type="text"
                variant="outlined"
                fullWidth
                id="name"
                label=""
                name="name"
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                {...register("name", { required: true })}
              />
              <div>
                {errors.name && (
                  <span className="error">Dies ist ein Pflichtfeld.</span>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel id="label">
                Nachname<span>*</span>
              </InputLabel>
              <TextField
                variant="outlined"
                type="text"
                fullWidth
                id="lastName"
                label=""
                name="lastName"
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                {...register("lastName", { required: true })}
              />
              <div>
                {errors.lastName && (
                  <span className="error">Dies ist ein Pflichtfeld.</span>
                )}
              </div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputLabel id="label">
                Adresse (Straße und Hausnummer) <span>*</span>
              </InputLabel>
              <TextField
                variant="outlined"
                fullWidth
                name="adresse"
                label=""
                type="text"
                id="adresse"
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                {...register("adresse", { required: true })}
              />
              <div>
                {errors.adresse && (
                  <span className="error">Dies ist ein Pflichtfeld.</span>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel id="label">Zusatz</InputLabel>
              <TextField
                variant="outlined"
                fullWidth
                name="zusatz"
                label=""
                type="text"
                id="zusatz"
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                {...register("zusatz", { required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel id="label">
                Postleitzahl <span>*</span>
              </InputLabel>
              <TextField
                variant="outlined"
                fullWidth
                name="postleitzahl"
                label=""
                type="number"
                id="postleitzahl"
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                {...register("postleitzahl", { required: true })}
              />
              <div>
                {errors.postleitzahl && (
                  <span className="error">Dies ist ein Pflichtfeld.</span>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel id="label">
                Stadt <span>*</span>
              </InputLabel>
              <TextField
                variant="outlined"
                fullWidth
                name="stadt"
                label=""
                type="text"
                id="stadt"
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                {...register("stadt", { required: true })}
              />
              <div>
                {errors.adresse && (
                  <span className="error">Dies ist ein Pflichtfeld.</span>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputLabel id="label">
                Land <span>*</span>
              </InputLabel>
              <TextField
                variant="outlined"
                fullWidth
                name="land"
                label=""
                type="text"
                id="land"
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                {...register("land", { required: true })}
              />
              <div>
                {errors.land && (
                  <span className="error">Dies ist ein Pflichtfeld.</span>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel id="label">
                Telefonnummer <span>*</span>
              </InputLabel>
              <TextField
                variant="outlined"
                fullWidth
                name="telNumber"
                label=""
                type="tel"
                id="number"
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                {...register("telNumber", { required: true })}
              />
              <div>
                {errors.telNumber && (
                  <span className="error">Dies ist ein Pflichtfeld.</span>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel id="label">
                E-Mail-Adresse <span>*</span>
              </InputLabel>
              <TextField
                variant="outlined"
                fullWidth
                name="email"
                label=""
                type="email"
                id="email"
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                {...register("email", { required: true })}
              />
              <div>
                {errors.email && (
                  <span className="error">Dies ist ein Pflichtfeld.</span>
                )}
              </div>
            </Grid>

            <Grid item xs={12}>
              <InputLabel id="label">Ihre Fragen zur Anmeldung</InputLabel>
              <textarea
                name="frage"
                label=""
                type="text"
                id="frage"
                rows={12}
                className={classes.notchedOutline}
                {...register("frage", { required: false })}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    {...register("informationen", { required: false })}
                    name="informationen"
                  />
                }
                label="Ich möchte aktuelle Informationen erhalten"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    {...register("saveData", { required: true })}
                    name="saveData"
                  />
                }
                label="Ich stimme der Verwendung der gemachten Daten zur Bearbeitung der Bestellung zu"
              />
              <span className="star">*</span>
              <div>
                {errors.saveData && (
                  <span className="error">Dies ist ein Pflichtfeld.</span>
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    {...register("dataProtection", { required: true })}
                    name="dataProtection"
                  />
                }
                label="Ich erkenne die Datenschutzbestimmungen an"
              />
                <span className="star">*</span>
              <div>
                {errors.dataProtection && (
                  <span className="error">Dies ist ein Pflichtfeld.</span>
                )}
              </div>
            </Grid>
          </Grid>
          <Button type="submit" variant="contained" className={classes.submit}>
            Anmelden
          </Button>
        </form>
      </div>
    </React.Fragment>
  );
}
