import React from "react";
import Layout from "../components/layout";
import { Container } from "@material-ui/core";
import FormData from "../components/Forms/FormData";
import Seeker from "../components/Main/Seeker";

const KnowledgeSeeker = () => {
  return (
    <Layout>
      <Container>
        <Seeker />
        <FormData />
      </Container>
    </Layout>
  );
};

export default KnowledgeSeeker;
