import React, { useState } from "react";
import Layout from "../components/layout";
import Stehen from '../components/Main/Wofür_stehen_wir';
import Banner from '../components/Main/Banner';
import axios from "axios";
import MediaCard from "../components/Main/MediaCard";
import {

  CircularProgress, Grid, Link,

} from "@material-ui/core";


const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataOnlinekurse, setDataOnlinekurse] = useState(null);
  const [dataWebinare, setDataWebinare] = useState(null);


  const update_data = async function () {
    setIsLoading(true);
    const LOCAL_DOMAINS = ["localhost", "127.0.0.1"];
    let BASE_URL_PUBLIC = "/api/courses/v1/courses/";

    /* offline */
    if (LOCAL_DOMAINS.includes(window.location.hostname)) {
      BASE_URL_PUBLIC = "http://kreiswert.localhost/api/courses/v1/courses/";
    }

    /* online || production && staging */
    else {
      BASE_URL_PUBLIC = "/api/courses/v1/courses/";
    }


    const result = await axios(
      BASE_URL_PUBLIC,
      {
        params: { page_size: 100 }
      }
    );
    setDataWebinare(result.data.results.filter((item) => {
      return item.org.toLowerCase() === "webinar";
    }));
    setDataOnlinekurse(
      result.data.results.filter((item) => {
        return item.org.toLowerCase() === "e-learning";
      })
    );

    setIsLoading(false);
  };
  React.useEffect(() => {
    update_data();
  }, []);


  let onlinecoruses = dataOnlinekurse
  let webinarecourses = dataWebinare


  // const settings = {
  //   className: "center",
  //   centerPadding: "60px",
  //   slidesToScroll: 4,
  //   slidesToShow: 4,
  //   infinite: false,
  //   speed: 500,
  //   responsive: [
  //     {
  //       breakpoint: 1400,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3,
  //         infinite: true,
  //         center: true,
  //       }
  //     },
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //         infinite: true,
  //         center: true,
  //       }
  //     },
  //     {
  //       breakpoint: 700,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //         centerMode: false,
  //         infinite: false
  //       }
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         centerMode: false,
  //         infinite: false,
  //         slidesToShow: 1,
  //         slidesToScroll: 1
  //       }
  //     }
  //   ]
  // };
  // const settings_filterd = {
  //   className: "center",
  //   centerMode: false,
  //   infinite: false,
  //   centerPadding: "60px",
  //   slidesToScroll: 4,
  //   slidesToShow: 4,
  //   speed: 500,
  //   responsive: [
  //     {
  //       breakpoint: 1400,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3,
  //         infinite: false
  //       }
  //     },
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //         infinite: false
  //       }
  //     },
  //     {
  //       breakpoint: 700,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //         centerMode: false,
  //         infinite: false
  //       }
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         centerMode: false,
  //         infinite: false,
  //         slidesToShow: 1,
  //         slidesToScroll: 1
  //       }
  //     }
  //   ]
  // };

  // const handleToggle = (e, btn_id) => {
  //   if (e.target.parentNode.classList.contains("is_active")) {
  //     setIsOneActive(null)
  //     setActiveButtonId(null);

  //   } else {
  //     setIsOneActive("not_active");
  //     setActiveButtonId(btn_id);
  //   }
  // };

  // const handleToggleSec = (e, btn_id) => {
  //   if (e.target.parentNode.classList.contains("is_active")) {
  //     setIsOneActiveSec(null)
  //     setActiveButtonIdSec(null);

  //   } else {
  //     setIsOneActiveSec("not_active");
  //     setActiveButtonIdSec(btn_id);
  //   }
  // };

  // const setCourse = length => {
  //   const oneCourse = " Kurs";
  //   const manyCourse = " Kurse";
  //   if (length === 1) {
  //     return length + oneCourse;
  //   } else {
  //     return length + manyCourse;
  //   }
  // };


  // const closeButton = () => {
  //   setIsOneActive(null);
  //   setActiveButtonId(null);
  // }

  // const closeButtonSec = () => {
  //   setIsOneActiveSec(null);
  //   setActiveButtonIdSec(null);
  // }



  // const settext = org => {
  //   if (org === "Coaching") {
  //     return <Coaching />
  //   } else if (org === "Digitalisierung") {
  //     return <Digitalisierung />
  //   }
  //   else if (org === "Grundlagen") {
  //     return <Grundlagen />
  //   }
  //   else if (org === "Führung") {
  //     return <Führung />
  //   }
  //   else if (org === "Innovation") {
  //     return <Innovation />
  //   }
  //   else if (org === "Pflichtschulungen") {
  //     return <Pflichtschulungen />
  //   }
  //   else if (org === "Supervision") {
  //     return <Supervision />
  //   }
  //   else if (org === "Verwaltung") {
  //     return <VerwaltungOrganisation />
  //   }
  //   else if (org === "Beratung") {
  //     return <Beratung />
  //   }
  //   else if (org === "KinderUndJugendhilfe") {
  //     return <KinderUndJugendhilfe />
  //   }
  //   else if (org === "Eingliederungshilfe") {
  //     return <Eingliederungshilfe />
  //   }
  //   else if (org === "SuchtUndDrogenhilfe") {
  //     return <SuchtUndDrogenhilfe />
  //   }
  //   else if (org === "Ehrenamt") {
  //     return <Ehrenamt />
  //   }
  //   else if (org === "Flüchtlingshilfe") {
  //     return <Flüchtlingshilfe />
  //   }
  //   else if (org === "Kindertagesbetreuung") {
  //     return <Kindertagesbetreuung />
  //   }
  //   else if (org === "Schulsozialarbeit") {
  //     return <Schulsozialarbeit />
  //   }
  //   else if (org === "Straffälligenhilfe") {
  //     return <Straffälligenhilfe />
  //   }


  // };



  return (
    <Layout>
      <Banner />
      <Stehen />
      {isLoading
        ? <div className="loader-wrapper">
          <CircularProgress className="loader" />
        </div>
        :
        <div className="slider-container just_sorted" id="onlineCourse">
          <h2 className="car-title">Unsere E-Learning</h2>
          <Grid container spacing={3}>
            {onlinecoruses.slice(0, 8).map((courseInfo) =>

              <MediaCard key={courseInfo.id} courseInfo={courseInfo} />

            )}
          </Grid>
          <div className="more-courses">
            <Link href="/courses">Zum Gesamten kursangebot</Link>
          </div>
        </div>

      }

      {isLoading
        ? <div className="loader-wrapper">
          <CircularProgress className="loader" />
        </div>
        :
        <div className="slider-container just_sorted">
          <h2 className="car-title">Unsere Webinare</h2>
          <Grid container spacing={3}>
            {webinarecourses.slice(0, 8).map((courseInfo) =>
              <MediaCard key={courseInfo.id} courseInfo={courseInfo} />
            )}
          </Grid>
          <div className="more-courses">
            <Link href="/courses">Zum Gesamten kursangebot</Link>
          </div>
        </div>}

    </Layout>
  );
};

export default Home;
