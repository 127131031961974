import React from "react"

import Layout from "../components/layout"
import Giver from "../components/Main/Giver";
import { Container } from "@material-ui/core";



const KnowledgeSeeker = () => {
    return (
        <Layout>
            <Container>
                <Giver />
            </Container>
        </Layout>
    )
}

export default KnowledgeSeeker