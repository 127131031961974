import React from "react";
import Markdown from "react-markdown";
import PrivacyPolicyJSON from "../data/privacyPolicy.json";
import { Container } from "@material-ui/core";

const  privacyPolicy= () => {
  return (
    <Container>
      <div className="datenschutz inline">
        <h1>{PrivacyPolicyJSON[0].title}</h1>
        <Markdown source={PrivacyPolicyJSON[0].content} escapeHtml={false} />
      </div>
    </Container>
  );
};

export default privacyPolicy;
