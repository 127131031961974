import React from "react";
import ReactMarkdown from "react-markdown";
import data from "../../data/knowledgesgiver.json";

const Giver = () => {
  return (
    <div className="giver-section">
      <ReactMarkdown source={data[0].content} />
    </div>
  );
};

export default Giver;
