import React, { useLayoutEffect } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "./app.scss";
import Home from "./pages/home";
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import Header from "./components/Header/header";
import Footer from "./components/Footer/footer";
import { Newsletter } from "./components/Main/Newsletter";
import KnowledgeGiver from "./pages/knowledgeGiver";
import SocialOrganization from "./pages/SocialOrganization";
import KnowledgeSeeker from "./pages/knowledgeSeeker";
import NotFound from "./pages/notfound";
import Impressum from "./pages/Impressum";
import PrivacyPolicy from "./pages/privacyPolicy";
import Thanks from "./components/Forms/thanks";
import AGBs from "./pages/AGBs";


const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}


ReactDOM.render(
  <Router>
    <Wrapper>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/knowledgeseeker" component={KnowledgeSeeker} />
        <Route exact path="/knowledgegiver" component={KnowledgeGiver} />
        <Route exact path="/socialbusinesses" component={SocialOrganization} />
        <Route exact path="/thanksforsubmitting" component={Thanks} />
        <Route exact path="/faq" component={NotFound} />
        <Route exact path="/datenschutz" component={PrivacyPolicy} />
        <Route exact path="/impressum" component={Impressum} />
        <Route exact path="/kontakt" component={NotFound} />
        <Route exact path="/agbs" component={AGBs} />
      </Switch>
      <Newsletter />
      <Footer />
    </Wrapper>
  </Router>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
