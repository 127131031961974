import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#fff",
  },
  items: {
    display: "flex",
    justifyContent: "start",
    color: "#000",
    listStyleType: "none",
    marginLeft: 22,
    paddingTop: 44,
    paddingBottom: 22,
    [theme.breakpoints.down("xs")]: {
      justifyContent: "left",
      flexDirection: "column",
      padding: "0 16px",
      marginLeft: 0,
    },
  },
  list: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: 16,
      marginTop: 16,
    },
  },
}));

const footers = ["Datenschutz", "Impressum", "AGBs"];

const Footer = () => {
  const classes = useStyles();

  return (
    <div component="footer" className={classes.footer}>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <ul className={classes.items}>
            {footers.map((item) => {
              return (
                <li className={classes.list} key={item}>
                  <Link to={item.toLowerCase()} className="textSecondary">
                    {item}
                  </Link>
                </li>
              );
            })}
            <li className={classes.list}>
              <Link to="/contact" className="textSecondary">
                Kontakt
              </Link>
            </li>
          </ul>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
