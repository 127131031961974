import React from "react";

import Layout from "../components/layout";
import { Container } from "@material-ui/core";
import Form from "../components/Forms/Form";
import Soical from "../components/Main/Socialorganizations";

const SocialOrganization = () => {
  return (
    <Layout>
      <Container>
        <Soical />
        <Form />
      </Container>
    </Layout>
  );
};

export default SocialOrganization;
