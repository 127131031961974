import React from "react"
import Layout from "../components/layout"

import {
    Link
  } from "@material-ui/core";
  import "./pages.css"
const NotFound = () => {
    return (
        <div>
        <Layout>
            <h2 className="notfound">The page you are looking for doesn't exist yet</h2>
            <Link  className="notfound-link" href="/">Go back to the Home Page</Link>
        </Layout>
        </div>
    )
}

export default NotFound