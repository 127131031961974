import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import {
  Hidden,
  Drawer,
  List,
  ListItem,
  Divider,
  Link,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import Logo from "../../images/kwiso-logo-black.png";
import CloseIcon from "@material-ui/icons/Close";
import useCookie from "./useCookies";
import { Menu, MenuItem } from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";

const drawerWidth = "75%";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: "#E50B4C",
    border: "1px solid #E50B4C",
    "&:hover": {
      backgroundColor: "#E50B4c",
      color: "#fff",
      border: "1px solid #E50B4C",
    },
  },
  menuButtonLogin: {
    marginRight: 6,
    backgroundColor: "#E50B4c",
    color: "#fff",
    border: "1px solid #E50B4C",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#E50B4C",
      border: "1px solid #E50B4C",
    },
  },
  title: {
    fontSize: "1rem",
  },
  header: {
    backgroundColor: "#fff",
  },
  lasttitle: {
    flexGrow: 1,
    color: "#000",
    fontSize: "1rem",
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      position: "relative",
    },
    "& ul": {
      paddingBottom: 0,
      paddingTop: 0,
    },
  },
  desktop: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  menuButtonTop: {
    justifyContent: "flex-end",
    marginRight: 15,
  },
  closeicon: {
    float: "right",
    marginRight: 15,
    fontSize: "2rem",
  },
  buttonList: {
    width: "100%",
  },
  drawer: {
    "& a": {
      color: "#E50B4C",
    },
    "& button": {
      marginRight: theme.spacing(2),
      backgroundColor: "#E50B4c",
      color: "#fff",
      border: "1px solid #E50B4C",
      "&:hover": {
        backgroundColor: "#fff",
        color: "#E50B4C",
        border: "1px solid #E50B4C",
      },
    },
  },
}));

export default function Header() {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isLoged, setIsLoged] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const cookie = useCookie("edxloggedin");
  const cookiesInfo = useCookie("edx-user-info");

  if (typeof cookiesInfo !== "undefined") {
    const replacedbad = cookiesInfo.replace(/\\054/g, ",");
    const cookiesJson = JSON.parse(JSON.parse(replacedbad));
    var userName = cookiesJson.username;
    var learner_profile = cookiesJson.header_urls.learner_profile;
    var account_settings = cookiesJson.header_urls.account_settings;
    var logout = cookiesJson.header_urls.logout;
  }

  const recordButtonPosition = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  React.useEffect(() => {
    if (cookie === "true") {
      setIsLoged(true);
    }
  }, [cookie]);

  const drawer = (
    <div className={classes.drawer}>
      <List>
        <CloseIcon className={classes.closeicon} onClick={handleDrawerToggle} />
        {/* <ListItem>
          <NavLink className="menu-item" to={"/knowledgeseeker"}>
            <MenuItem onClick={closeMenu}>Für Wissenssucher:innen</MenuItem>
          </NavLink>
        </ListItem>
        <Divider className={classes.divider} /> */}
        <ListItem>
          <NavLink className="menu-item" to={"/socialbusinesses"}>
            <MenuItem onClick={closeMenu}>Für soziale Organisationen</MenuItem>
          </NavLink>
        </ListItem>
        <Divider className={classes.divider} />
        <ListItem>
          <NavLink className="menu-item" to={"/knowledgegiver"}>
            <MenuItem onClick={closeMenu}>Für Wissensanbieter:innen </MenuItem>
          </NavLink>
        </ListItem>
        
        <Divider className={classes.divider} />
      </List>
      {!isLoged ? (
        <React.Fragment>
        <List>
          <ListItem>
            <Link className={`${classes.title} menu-item`} href={"/register"}>
              <Button
                className={classes.buttonList}
                variant="contained"
                color="default"
              >
                Registrieren
              </Button>
            </Link>
          </ListItem>
          <ListItem>
            <Link className={`${classes.title} menu-item`} href={"/login"}>
              <Button
                className={classes.buttonList}
                variant="contained"
                color="default"
              >
                Anmelden
              </Button>
            </Link>
          </ListItem>
        </List>
        <Divider className={classes.divider} />

        </React.Fragment>
      ) : (
        <React.Fragment>
          <List>
            <MenuItem className={classes.profile}>
              <Link href="/dashboard">
                {" "}
                <MenuItem onTouchTap={closeMenu}>Dashboard</MenuItem>
              </Link>
            </MenuItem>
            <Divider className={classes.divider} />
            <MenuItem className={classes.profile}>
              <Link href={learner_profile}>
                <MenuItem onTouchTap={closeMenu}>Profile</MenuItem>
              </Link>
            </MenuItem>
            <Divider className={classes.divider} />
            <MenuItem>
              <Link href={account_settings}>
                <MenuItem onTouchTap={closeMenu}>Account</MenuItem>
              </Link>
            </MenuItem>
            <Divider className={classes.divider} />
            <MenuItem>
              <Link href={logout}>
                <MenuItem onTouchTap={closeMenu}>Abmelden</MenuItem>
              </Link>
            </MenuItem>
          </List>
          <Divider className={classes.divider} />
        </React.Fragment>
      )}
    </div>
  );

  return (
    <React.Fragment>
      <AppBar position={"fixed"} className={classes.header}>
        <Toolbar className="header-desktop">
          <a href="/courses">
            <img className="logo" src={Logo} alt="logo" />
          </a>
          {/* <NavLink
            className={`${classes.title} menu-item with-border`}
            to={"/knowledgeseeker"}
          >
            Für Wissenssucher:innen
          </NavLink> */}
          <NavLink
            className={`${classes.title} menu-item with-border`}
            to={"/socialbusinesses"}
          >
            Für soziale Organisationen
          </NavLink>
          <NavLink
            className={`${classes.title}  menu-item flow`}
            to={"/knowledgegiver"}
          >
            Für Wissensanbieter:innen
          </NavLink>
          {!isLoged ? (
            <div className="login-buttons">
              <Link
                className={`${classes.title} menu-item  menu-item-button`}
                href={"/register"}
              >
                <Button className={classes.menuButton} color="inherit">
                  Registrieren
                </Button>
              </Link>
              <Link
                className={`${classes.title} menu-item menu-item-button`}
                href={"/login"}
              >
                <Button className={classes.menuButtonLogin} color="inherit">
                  Anmelden
                </Button>
              </Link>
            </div>
          ) : (
            <div className="login-buttons">
              <p className="text">{userName}</p>
              <IconButton
                aria-haspopup="true"
                onClick={recordButtonPosition}
                className={classes.circlIcon}
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={closeMenu}
              >
                <MenuItem className={classes.profile}>
                  <Link href="/dashboard">Dashboard</Link>
                </MenuItem>
                <MenuItem className={classes.profile}>
                  <Link href={learner_profile}>Profile</Link>
                </MenuItem>
                <MenuItem>
                  <Link href={account_settings}>Account</Link>
                </MenuItem>
                <MenuItem>
                  <Link href={logout}>Abmelden</Link>
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
        <div className="header-mobile">
          <a href="/courses">
            <img className="logo-mobile" src={Logo} alt="logo" />
          </a>
          <IconButton
            aria-label="Open drawer"
            onClick={handleDrawerToggle}
            className={classes.menuButtonTop}
          >
            <MenuIcon />
          </IconButton>
        </div>
      </AppBar>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </React.Fragment>
  );
}
