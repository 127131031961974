import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import SpeedIcon from "@material-ui/icons/Speed";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ContactsIcon from "@material-ui/icons/Contacts";
import data from "../../data/iconssection.json";

import ReactMarkdown from "react-markdown";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 44,
  },
  paper: {
    height: "auto",
    width: 300,
    padding: "0 15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 15,
    boxShadow: "none",
    "& p": {
      marginTop: 0,
    },
  },
  control: {
    padding: theme.spacing(2),
  },
  image: {
    height: 80,
    paddingRight: 15,
    marginBottom: 15,
  },
  head: {
    textAlign: "center",
    color: "#E50B4C",
  },
  Grid: {
    marginRight: 15,
  },
  text: {
    textAlign: "center",
    color: "#000",
  },
  span: {
    display: "block",
    color: "#E50B4C",
    fontWeight: 700,
    fontFamily: `"MerriweatherBold", serif`,
  },
  center: {
    justifyContent: "center",
  }
}));

export default function Stehen() {
  const classes = useStyles();
  return (
    <Grid container className={classes.root} id="blog">
      <Grid item xs={12}>
        <h2 className={classes.head}>Wofür stehen wir?</h2>
        <Grid container className={classes.center}>
          <Grid className={classes.Grid}>
            <Paper className={classes.paper}>
              <ContactsIcon />
              <ReactMarkdown
                className={classes.text}
                source={data[0].content}
                escapeHtml={false}
              />
            </Paper>
          </Grid>
          <Grid className={classes.Grid}>
            <Paper className={classes.paper}>
              <TrackChangesIcon />

              <ReactMarkdown
                className={classes.text}
                source={data[2].content}
                escapeHtml={false}
              />
            </Paper>
          </Grid>
          <Grid className={classes.Grid}>
            <Paper className={classes.paper}>
              <SpeedIcon />
              <ReactMarkdown
                className={classes.text}
                source={data[1].content}
                escapeHtml={false}
              />
            </Paper>
          </Grid>
          <Grid className={classes.Grid}>
            <Paper className={classes.paper}>
              <ThumbUpIcon />
              <ReactMarkdown
                className={classes.text}
                source={data[3].content}
                escapeHtml={false}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
